import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/lib/services/core.service';
import { Res } from 'src/app/lib/rest/rest-api';
import { Router } from '@angular/router';
import { Client } from 'src/app/lib/classes/client';
import { List } from 'src/app/lib/classes/list';
import { NbToastrService } from '@nebular/theme';
import { isNil } from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})


export class ListComponent extends List<Client> implements OnInit {

  constructor(private _core: CoreService, private router: Router, private toastr: NbToastrService, private storage: LocalStorageService) {
    super();
    this._filtros = [
      { value: 'codigo', label: 'Código' },
      { value: 'idenFiscal', label: 'NIT' },
      { value: 'idenPersonal', label: 'DPI' },
      { value: 'nombreCompleto', label: 'Nombre' }
    ];
    this._rsc = this._core.api.get('client');
  }

  ngOnInit() {
    this.load();
  }

  filterBy(filter) {
    if (filter[1].length) {
      this._filterBy = `&${filter[0]}=${filter[1]}`;
    } else {
      this._filterBy = '';
    }
    this.load();
  }

  async load(offset = 0) {
    this._core.spinnerOn();
    try {
      const clients: Res<Client> = await this._rsc.find(`offset=${offset}&limit=10${this._filterBy}&vendedorId=${this.storage.retrieve('user')._id}`).toPromise() as Res<Client>;
      this._data = clients.res;
      this._pages = Math.ceil(clients.numReg / 10);
      this._core.spinnerOff();
    } catch (error) {
      this._core.spinnerOff();
      this.toastr.danger('Problemas al conectarse con el servidor', 'Error', { duration: 0 });
    }
  }

  addNew() {
    this.router.navigate(['/client/maintenance/new']);
  }

  edit(id: string) {
    this.router.navigate(['/client/maintenance/edit/' + id]);
  }

}
