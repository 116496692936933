import { Component, OnInit } from '@angular/core';
import { List } from 'src/app/lib/classes/list';
import { OrderList, Order } from 'src/app/lib/classes/order';
import { CoreService } from 'src/app/lib/services/core.service';
import { Res, EntityService } from 'src/app/lib/rest/rest-api';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { User } from 'src/app/public/public.service';
import { LocalStorageService } from 'ngx-webstorage';
import { isArray } from 'util';
import { VoidDocumentComponent } from '../components/void-document/void-document.component';
import { isNil } from 'lodash';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends List<OrderList> implements OnInit {

  private _srcUser: EntityService<User>;
  private _rscOrder: EntityService<Order>;
  private _localUser: any;
  private _sellerId = '';
  private _sellersList: User[] = [];

  constructor(
    private _core: CoreService,
    private toastr: NbToastrService,
    private router: Router,
    private storage: LocalStorageService,
    private dialog: NbDialogService
  ) {
    super();
    this._filtros = [
      { value: 'correlativo', label: 'Correlativo' },
      { value: 'cliente', label: 'Nombre Negocio'}
    ];
    this._rsc = this._core.api.get('order');
    this._rscOrder = this._core.api.get('order');
    this._srcUser = this._core.api.get('user');
    this._localUser = this.storage.retrieve('user');
  }

  async ngOnInit() {
    if (this._localUser.role !== 'VENTAS_ROLE') {
      const sellers: User = await this._srcUser.findById(this._localUser._id).toPromise();
      this._sellersList = sellers.usuarios;
    } else {
      this.load(0);
    }
  }

  load(event: Array<any> | number) {
    this._core.spinnerOn();
    setTimeout(async () => {
      try {
        const orders: Res<OrderList> = await this._rsc.findById(this._localUser._id,
          `lista?${isArray(event) ? (event[0] === 'correlativo' ? 'correlativo=' : 'cliente=') + event[1] :
          'offset=' + event}&limit=10${(this._sellerId !== '' ? '&vendedorId=' + this._sellerId : '')}`)
          .toPromise() as Res<OrderList>;
        this._data = orders.res;
        this._pages = Math.ceil(orders.numReg / 10);
        this._core.spinnerOff();
      } catch (error) {
        this._core.spinnerOff();
        this.toastr.warning(error.error.error, 'Atención', { duration: 0 });
      }
    }, 0);
  }

  addNew() {
    this.router.navigate(['/order/maintenance/new']);
  }

  edit(id: string) {
    this.router.navigate(['/order/maintenance/edit/' + id]);
  }

  print(order: OrderList) {
    try {
      this._core.spinnerOn();
      const url = encodeURI(`https://api.gapa.work/v1/impresion/${order.id}`);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `5d35365619b48b398435f843.pdf`);
      link.click();
      this.router.navigate(['/order/maintenance']);
    } catch (error) {
      this.toastr.danger(error.error.errores, 'Error');
    } finally {
      this._core.spinnerOff();
    }
  }

  void(pedido: Order) {
    const voidDoc = this.dialog.open(VoidDocumentComponent,
      {
        context: {
          documentId: pedido.id,
          rsc: this._rscOrder
        }
      }).onClose.subscribe((flag: boolean) => {
        if (flag) {
          this.toastr.success('Documento Anulado.');
          pedido.estado = 1;
        }
        voidDoc.unsubscribe();
      });
  }

  get sellerId(): string {
    return this._sellerId;
  }

  set sellerId(id: string) {
    this._sellerId = id;
  }

  get sellersList(): Array<any> {
    return this._sellersList;
  }

  get rolUser(): boolean {
    return this._localUser.role !== 'VENTAS_ROLE';
  }

}
