<div class="d-flex justify-content-center align-items-center h-100">
  <nb-card size="large" class="m-0 w-95">
    <nb-card-header>
      <app-search title="Pedido" typeText="text" [filtros]="filtros" check="correlativo" like="true"
        (search)="load($event)">
      </app-search>
    </nb-card-header>
    <nb-card-body>
      <div class="row" *ngIf="rolUser">
          <div class="col">
            <div class="form-group">
              <nb-select (ngModelChange)="load()" [(ngModel)]="sellerId" placeholder="Vendedores" status="success" fullWidth>
                <nb-option *ngFor="let seller of sellersList" [value]="seller._id">
                  {{seller.entidad?.nombreCompleto}}
                </nb-option>
              </nb-select>
            </div>
          </div>
        </div>
      <nb-list>
        <nb-list-item *ngFor="let pedido of data">
          <nb-card>
            <nb-card-header style="position: relative" class="text-right">
              <nb-badge *ngIf="pedido.estado !== 1" text="{{pedido.serie}} - {{pedido.correlativo}}" status="info" position="top left"></nb-badge>
              <nb-badge *ngIf="pedido.estado === 1" text="{{pedido.serie}} - {{pedido.correlativo}}" status="danger" position="top left"></nb-badge>
              {{pedido.fecha | date: 'dd/MM/yyyy'}}
            </nb-card-header>
            <nb-card-body class="w-100 d-flex justify-content-between">
              {{pedido.clienteId?.nombreNegocio}}
              <button nbButton size="small" shape="semi-round" type="button" status="primary" (click)="print(pedido)"><i
                class="fas fa-print"></i></button>
            </nb-card-body>
            <nb-card-footer style="position: relative">
              <nb-badge class="fz-m" text="{{pedido.total | number:'0.2-2'}}" status="success" position="bottom right"></nb-badge>
              <button *ngIf="pedido.estado !== 1" nbButton size="small" shape="semi-round" type="button" status="danger" (click)="void(pedido)"><i
                class="fas fa-ban"></i></button>
            </nb-card-footer>
          </nb-card>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col">
          <app-paginator (pageChanged)='load($event)' [pages]="pages"></app-paginator>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</div>