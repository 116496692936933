import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListComponent as ClientList } from './entity/list/list.component';
import { NewEditComponent as ClientNewEdit } from './entity/new-edit/new-edit.component';

import { ClientService } from './client.service';
import { AuthGuardService } from '../guards/auth-guard.service';
import { CheckListGuard } from '../guards/check-list.guard';
import { CheckRouteGuard } from '../guards/check-route.guard';


const routes: Routes = [{
  path: '',
  component: ClientList,
  canActivate: [AuthGuardService],
  resolve: {
    service: ClientService
  }
}, {
  path: 'new',
  component: ClientNewEdit,
  canActivate: [AuthGuardService, CheckRouteGuard],
  resolve: {
    service: ClientService
  },
}, {
  path: 'edit/:id',
  component: ClientNewEdit,
  canActivate: [AuthGuardService, CheckListGuard],
  resolve: {
    service: ClientService
  },
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class RoutingModule { }
