import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchComponent } from './search/search.component';
import { AlertComponent } from './alert/alert.component';
import { PaginatorComponent } from './paginator/paginator.component';

const component = [
  SearchComponent,
  AlertComponent,
  PaginatorComponent
];

@NgModule({
  declarations: [...component, PaginatorComponent],
  imports: [
    SharedModule,
  ], exports: [...component],
  entryComponents: [AlertComponent]
})
export class ComponentsModule { }
