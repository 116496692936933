import { NgModule } from '@angular/core';
import { ListComponent } from './list/list.component';
import { NewEditComponent } from './new-edit/new-edit.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ComponentsModule } from 'src/app/lib/components/components.module';
import { HeadComponent } from './components/head/head.component';
import { MapComponent } from './components/map/map.component';
import { PhotoComponent } from './components/photo/photo.component';
import { PictureComponent } from './components/picture/picture.component';

@NgModule({
  declarations: [ListComponent, NewEditComponent, HeadComponent, MapComponent, PhotoComponent, PictureComponent],
  imports: [
    SharedModule,
    ComponentsModule
  ]
})
export class EntityModule { }
