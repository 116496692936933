<div class="d-flex justify-content-center align-items-center h-100">
  <form [formGroup]="form" (ngSubmit)="save()" class="d-flex justify-content-center">
    <nb-card size="large" class="m-0 w-95">
      <nb-card-header class="d-flex justify-content-between">
        <i (click)="goPay()" class="fas fa-chevron-left"></i>
        <div>&nbsp;</div>
        <button nbButton size="small" type="submit" shape="semi-round" status="primary">
          <i class="fas fa-save"></i>
        </button>
      </nb-card-header>
      <nb-card-body class="min-w">
        <div class="row">
          <div class="col">
            {{form.value.cliente.entidad.nombreCompleto}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            {{form.value.cliente.entidad.idenFiscal}}
          </div>
        </div>
        <table class="table table-hover">
          <tbody>
            <tr *ngFor="let product of form.value.detalle">
              <td>{{product.cantidad}} <small>{{product.variante.nombre}} {{product.producto.nombre}} {{product.cantidad * product.precio | number:'0.2-2'}} - {{product.descuento | number:'0.2-2'}}</small></td>
              <td class="text-right">Q {{product.subTotal | number:'0.2-2'}}</td>
            </tr>
            <tr>
              <td colspan="3" class="text-right">
                <b>Q. {{form.value.subTotal  | number:'0.2-2'}}</b>
                <!--<b>Q {{form.value.subTotal  | number:'0.2-2'}} - {{form.value.descuento  | number:'0.2-2'}} = {{ form.value.subTotal- form.value.descuento  | number:'0.2-2'}}</b>-->
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col">
            <b>Pagos</b>
          </div>
        </div>
        <table class="table table-hover">
          <tbody>
            <tr *ngFor="let payment of form.value.detallePagos">
              <td>{{payment.metodoPago.nombre}}</td>
              <td class="text-right">Q {{payment.valor | number:'0.2-2'}}</td>
            </tr>
            <tr>
              <td colspan="3" class="text-right">
                <b>Q {{amountPayment | number:'0.2-2'}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </nb-card-body>
      <nb-card-footer style="position: relative" class="d-flex justify-content-between">
      </nb-card-footer>
    </nb-card>
  </form>
</div>