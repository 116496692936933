import { NgModule } from '@angular/core';
import { MenuComponent } from './menu/menu.component';
import { SharedModule } from '../shared/shared.module';
import { RoutingModule } from './routing.module';
import { CheckListComponent } from './check-list/check-list.component';

@NgModule({
  declarations: [MenuComponent, CheckListComponent],
  imports: [
    SharedModule,
    RoutingModule
  ]
})
export class StartModule { }
