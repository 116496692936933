import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderDetail } from 'src/app/lib/classes/order';
import { round } from 'lodash';
import { FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-general-discount',
  templateUrl: './general-discount.component.html',
  styles: []
})
export class GeneralDiscountComponent implements OnInit {

  private _form: FormGroup;
  private _products: OrderDetail[];
  private _descuento = 0;
  private _totalPedido = 0;
  private _total = 0;

  @Output() actualPage = new EventEmitter<string>();

  constructor(private toastr: NbToastrService) { }

  ngOnInit() {
    this._products = this._form.value.detalle as OrderDetail[];
    this.totalProduct();
  }

  private totalProduct() {
    this._totalPedido = this._products.reduce((total, detail) => {
      return total += round(detail.subTotal, 2);
    }, 0);
    this.actualPage.emit('discount');
  }

  goPay() {
    if (this._descuento > this._totalPedido) {
      this.toastr.warning('El descuento no puede ser mayor al total del pedido.');
      return;
    }
    if (this._descuento < 0) {
      this.toastr.warning('El descuento no puede ser negativo.');
      return;
    }
    this._form.patchValue({descuento: round(this._descuento, 2)});
    this.actualPage.emit('pay');
  }

  close() {
    this.actualPage.emit('product');
  }

  get descuento(): number {
    return this._descuento;
  }

  set descuento(valor: number) {
    this._descuento = valor;
    this._total = this._totalPedido - this._descuento;
  }

  get total(): number {
    return this._total;
  }

  get totalPedido(): number {
    return this._totalPedido;
  }

  @Input() set form(value: FormGroup) {
    this._form = value;
  }

}
