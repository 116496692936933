import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/lib/classes/product';
import { List } from 'src/app/lib/classes/list';
import { CoreService } from 'src/app/lib/services/core.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { Res } from 'src/app/lib/rest/rest-api';
import { FormGroup } from '@angular/forms';
import { ProductListComponent } from '../product-list/product-list.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends List<Product> implements OnInit {

  private _form: FormGroup;
  @Output() actualPage = new EventEmitter();
  constructor(private _core: CoreService, private toastr: NbToastrService, private dialog: NbDialogService) {
    super();
    this._filtros = [
      { value: 'code', label: 'Código' },
      { value: 'nit', label: 'NIT' },
      { value: 'name', label: 'Nombre' }
    ];
    this._rsc = this._core.api.get('product');
  }

  ngOnInit() {
    this.load();
  }

  async load(offset = 0) {
    this._core.spinnerOn();
    try {
      const products: Res<Product> = await this._rsc.find(`offset=${offset}&limit=10`).toPromise() as Res<Product>;
      this._data = products.res;
      this._pages = Math.ceil(products.numReg / 10);
      this._core.spinnerOff();
    } catch (error) {
      this._core.spinnerOff();
      this.toastr.danger('Problemas al conectarse con el servidor', 'Error', { duration: 0 });
    }
  }

  setProduct(product: Product) {
    this._form.patchValue({
      product
    });
    this.actualPage.emit('order');
  }

  productList() {
    this.dialog.open(ProductListComponent,
      {
        context: {
          products: this._form.value.detalle
        }
      });
  }

  goPay() {
    this.actualPage.emit('pay');
  }

  goDiscount() {
    this.actualPage.emit('discount');
  }

  @Input() set form(value: FormGroup) {
    this._form = value;
  }

}
