<div class="d-flex justify-content-center align-items-center h-100">
  <form [formGroup]="formPayment" (ngSubmit)="addPayment()">
    <nb-card size="large" class="m-0">
      <nb-card-header class="d-flex justify-content-between">
        <i (click)="goProducts()" class="fas fa-chevron-left"></i>
        <span>
            <h6>Q {{actualAmount | number:'0.2-2'}}</h6>
          </span>
        <button nbButton size="small" type="button" (click)="summary()" shape="semi-round" status="primary"><i class="fas fa-eye"></i></button>
      </nb-card-header>
      <nb-card-body class="min-w">
        <div class="form-group">
          <nb-select (ngModelChange)="payment($event)" placeholder="Metodo de pago" formControlName="metodoPagoId"
            status="success" fullWidth>
            <nb-option *ngFor="let method of paymentMethod" [value]="method._id">{{method.nombre}}</nb-option>
          </nb-select>
        </div>
        <div class="form-group" *ngIf="paymentType === 'Deposito'">
          <nb-select placeholder="Banco" formControlName="banco" status="success" fullWidth>
            <nb-option value="GYTCONTINENTAL">G&T CONTINENTAL</nb-option>
            <nb-option value="BANRURAL">BANRURAL</nb-option>
            <nb-option value="BAM">BAM</nb-option>
          </nb-select>
        </div>
        <div class="form-group" *ngIf="paymentType !== 'Efectivo'">
          <input formControlName="referencia" class="text-right" type="number" nbInput fullWidth status="success"
            placeholder="Referencia">
        </div>
        <div class="form-group">
          <input formControlName="valor" class="text-right" type="number" nbInput fullWidth status="success"
            placeholder="Valor">
        </div>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton size="small" type="button" (click)="paymentList()" shape="semi-round" status="info"><i
            class="fas fa-bars"></i></button>
        <button nbButton size="small" type="submit" shape="semi-round" status="primary"><i
            class="fas fa-plus"></i></button>
      </nb-card-footer>
    </nb-card>
  </form>
</div>