import { Injectable } from '@angular/core';
import { CoreService } from '../lib/services/core.service';

export interface Login {
  usuario: string;
  password: string;
  id: string;
}

export interface User {
  usuario: Usuario;
  token: string;
  id: string;
  menu: any[];
  usuarios?: any[];
}

export interface Usuario {
  role: string;
  empresa: any[];
  _id: string;
  entidad: string;
  usuario: string;
  email: string;
  password: string;
}


@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private _core: CoreService) {
  }
  resolve(): Promise<any> {
    this._core.api.add<Login>('login', 'login');
    this._core.api.add<Login>('version', 'version');
    return Promise.resolve();
  }
}
