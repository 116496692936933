<nb-card size="small" class="m-0">
  <nb-card-header class="d-flex justify-content-between">
    <i class="fas fa-times" (click)="close()"></i>
    <span>Anulación</span>
    <div>&nbsp;</div>
  </nb-card-header>
  <nb-card-body>
    <div class="form-group">
      <input [(ngModel)]="motivo" type="text" nbInput fullWidth status="success"
        placeholder="Motivo">
    </div>
  </nb-card-body>
  <nb-card-footer class="text-right">
      <button nbButton size="small" shape="semi-round" type="button" status="warning" (click)="void()" disabled="motivo===''"><i
        class="fas fa-check"></i></button>
  </nb-card-footer>
</nb-card>
