<div class="d-flex justify-content-center">
  <nb-card [status]="color" [accent]="color" class="w-75">
    <nb-card-header class="text-center">
      <h1 class="text-white">!ALERTA¡</h1>
    </nb-card-header>
    <nb-card-body class="d-flex flex-column justify-content-around align-items-center">
      <h1><i class="fas {{icon}} text-{{color}}"></i></h1>
      <span>{{body}}</span>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button type="button" (click)="ok()" nbButton shape="semi-round" status="primary">
        <i class="fas fa-check"></i>
      </button>
      <button type="button" (click)="no()" nbButton shape="semi-round" status="danger">
        <i class="fas fa-times"></i>
      </button>
    </nb-card-footer>
  </nb-card>
</div>