import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/lib/services/core.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Form } from 'src/app/lib/classes/form';
import { Login, User } from '../public.service';
import { NbToastrService } from '@nebular/theme';
import { EntityService } from 'src/app/lib/rest/rest-api';
import { isNil } from 'lodash';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends Form<any> implements OnInit {

  private _navigator: any;
  private readonly VERSION: number = 1641172094877;
  private _rscVersion: EntityService<any>;
  constructor(private builder: FormBuilder, private router: Router,
              private storage: LocalStorageService, private _core: CoreService,
              private toastr: NbToastrService) {
    super();
    this._navigator = navigator;
    this._form = this.builder.group({
      usuario: [null, [Validators.required, Validators.minLength(3)]],
      password: [null, [Validators.required, Validators.minLength(3)]],
    });
    this._rsc = this._core.api.get('login');
    this._rscVersion = this._core.api.get('version');
  }

  ngOnInit() {
    this._core.resetAuth();
  }

  conectBlueTooth() {
    if (!('bluetooth' in navigator)) {
      console.log('Bluetooth API not supported');
      return;
    }
    this._navigator.bluetooth.requestDevice({
      acceptAllDevices: true
    }).then((device) => {
      console.log('Connecting to GATT Server...');
      return device.gatt.connect();
    }).then(server => {
      // Note that we could also get all services that match a specific UUID by
      // passing it to getPrimaryServices().
      console.log('Getting Services...');
      return server.getPrimaryServices();
    }).then(services => {
      console.log('Getting Characteristics...');
      let queue = Promise.resolve();
      services.forEach(service => {
        queue = queue.then(_ => service.getCharacteristics().then(characteristics => {
          console.log('> Service: ' + service.uuid);
          characteristics.forEach(characteristic => {
            console.log('>> Characteristic: ' + characteristic.uuid + ' ' +
              this.getSupportedProperties(characteristic));
          });
        }));
      });
      return queue;
    }).catch(error => {
      console.log('Argh! ' + error);
    });
  }

  getSupportedProperties(characteristic) {
    const supportedProperties = [];
    for (const p in characteristic.properties) {
      if (characteristic.properties[p] === true) {
        supportedProperties.push(p.toUpperCase());
      }
    }
    return '[' + supportedProperties.join(', ') + ']';
  }

  async send() {
    if (this._form.invalid) {
      this.toastr.warning('Los campos en VERDE son obligatorios');
      return;
    }
    this._core.spinnerOn();
    try {
      const version = await this._rscVersion.findById(`${this.VERSION}`).toPromise();
      if (!isNil(version.res) && version.res.version === this.VERSION) {
        try {
          const user: User = await this._rsc.insert(this._form.value).toPromise();
          this.storage.store('token', user.token);
          this.storage.store('user', user.usuario);
          this.router.navigate(['/start/menu']);
        } catch (error) {
          this.toastr.warning(error.error.error);
        }
      } else {
        this.toastr.danger('No se ha podido validar la version.');
      }
    } catch (error) {
      this.toastr.danger(error.error.error, 'ACTUALIZAR APP', {duration: 3000});
      this.toastr.warning('NO TRADUCIR LA APP A ESPAÑOL.', 'DESINSTALE E INSTALE', {duration: 3500});
    } finally {
      this._core.spinnerOff();
    }
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }
}
