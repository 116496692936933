<form [formGroup]="form">
  <div class="row form-group">
    <div class="col-5">
      <input type="text" formControlName="codigo" class="form-control" nbInput fullWidth placeholder="Código">
    </div>
    <div class="col-7">
      <input type="text" formControlName="nombreNegocio" class="form-control" nbInput fullWidth
        placeholder="Nombre Negocio">
    </div>
  </div>
  <div formGroupName="entidad">
    <div class="row form-group">
      <div class="col">
        <input type="text" formControlName="idenFiscal" class="form-control" nbInput fullWidth placeholder="NIT">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-6">
        <input type="text" status="success" formControlName="nombre1" class="form-control" nbInput fullWidth
          placeholder="Nombre 1">
      </div>
      <div class="col-6">
        <input type="text" formControlName="nombre2" class="form-control" nbInput fullWidth placeholder="Nombre 2">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-6">
        <input type="text" status="success" formControlName="apellido1" class="form-control" nbInput fullWidth
          placeholder="Apellido 1">
      </div>
      <div class="col-6">
        <input type="text" formControlName="apellido2" class="form-control" nbInput fullWidth placeholder="Apellido 2">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-6">
        <input type="tel" formControlName="telefono" class="form-control" nbInput fullWidth
          placeholder="Teléfono">
      </div>
      <div class="col-6">
        <input type="tel" formControlName="movil" class="form-control" nbInput fullWidth placeholder="Celular">
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <input type="email" formControlName="correo" nbInput fullWidth placeholder="Correo">
      </div>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-12">
      <nb-select placeholder="Clasificación" fullWidth formControlName="clasificacion">
        <nb-option value="Distribuidor">Distribuidor</nb-option>
        <nb-option value="Mayorista">Mayorista</nb-option>
        <nb-option value="Minorista">Minorista</nb-option>
      </nb-select>
    </div>
  </div>
</form>