import { Component, OnInit } from '@angular/core';
import { PaymentDetail } from 'src/app/lib/classes/order';
import { NbDialogRef } from '@nebular/theme';
import { round } from 'lodash';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {

  private _payments: PaymentDetail[] = [];
  private _total = 0;
  constructor(private ref: NbDialogRef<boolean>) { }

  ngOnInit() {
    this.totalPayments();
  }

  close() {
    this.ref.close(true);
  }

  delete(payment: PaymentDetail) {
    this._payments.splice(this._payments.indexOf(payment), 1);
    this.totalPayments();
  }

  totalPayments() {
    this._total = this._payments.reduce((total, detail) => {
      return total += round(detail.valor, 2);
    }, 0);
  }

  get payments(): PaymentDetail[] {
    return this._payments;
  }

  set payments(value: PaymentDetail[]) {
    this._payments = value;
  }

  get total(): number {
    return this._total;
  }

}
