import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  NbCardModule, NbInputModule, NbButtonModule, NbSelectModule,
  NbListModule, NbUserModule, NbPopoverModule, NbRadioModule, NbDialogModule, NbToastrModule, NbBadgeModule
} from '@nebular/theme';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxFabModule } from 'ngx-fab';
import { NgxSpinnerModule } from 'ngx-spinner';
import { WebcamModule } from 'ngx-webcam';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';


const modules = [
  CommonModule,
  NbCardModule,
  NbInputModule,
  NbButtonModule,
  NbSelectModule,
  NbListModule,
  NbUserModule,
  NbPopoverModule,
  NbRadioModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  NgxFabModule,
  NgxSpinnerModule,
  WebcamModule,
  NgScrollbarModule,
  NbBadgeModule,
  NbSelectModule,
  UiSwitchModule,
  NbPopoverModule
];

const services = [
];


@NgModule({
  declarations: [],
  imports: [
    ...modules,
    NgxWebstorageModule.forRoot({
      prefix: 'this',
      caseSensitive: false,
      separator: '-'
    }),
    NbDialogModule.forRoot({
      closeOnBackdropClick: false,
      closeOnEsc: false
    }),
    NbToastrModule.forRoot(),
    UiSwitchModule.forRoot({
      switchColor: '#001443',
    })
  ],
  exports: [...modules],
  providers: [...services],
  entryComponents: []
})
export class SharedModule { }
