import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CoreService } from 'src/app/lib/services/core.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Form } from 'src/app/lib/classes/form';
import { LocalStorageService } from 'ngx-webstorage';
import { ProductListComponent } from '../product-list/product-list.component';
import { OrderDetail, PriceList } from 'src/app/lib/classes/order';
import { Res } from 'src/app/lib/rest/rest-api';
import { isNil, round } from 'lodash';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent extends Form<OrderDetail> implements OnInit {
  private _formOrder: FormGroup;
  protected _priceList: PriceList[];
  private _priceListName: { id: string, nombre: string }[] = [];
  private _actualAmount = 0;
  private _isBono = false;
  @Output() actualPage = new EventEmitter();
  constructor(private _core: CoreService, private toastr: NbToastrService, private builder: FormBuilder,
              private dialog: NbDialogService, private storage: LocalStorageService) {
    super();
    this._formOrder = this.builder.group({
      productoId: [null, Validators.required],
      producto: [null, Validators.required],
      varianteId: [null, Validators.required],
      variante: [null, Validators.required],
      uniMedVarianteId: [null, Validators.required],
      uniMedVariante: [null, Validators.required],
      cantidad: [null, Validators.required],
      listaPrecioId: [null],
      precio: [null],
      descuento: [0],
      subTotal: [null, Validators.required],
      bonificacion: [false, Validators.required]
    });
    this._rsc = this._core.api.get('priceList');
  }

  ngOnInit() {
    this.loadPriceList();
  }

  async loadPriceList() {
    try {
      this._core.spinnerOn();
      const priceList: Res<PriceList[]> = await
      this._rsc.find(`vendedorId=${this.storage.retrieve('user')._id}`).toPromise() as Res<PriceList[]>;
      this._priceList = priceList.res;
      priceList.res.forEach(price => {
        this._priceListName.push({ id: price.id, nombre: price.nombre });
      });
      this._core.spinnerOff();
    } catch (error) {
      this._core.spinnerOff();
    }
  }

  amount(isBono = false) {
    let precio = 0;
    if (!isBono) {
      const priceList = this._priceList.find((params) => params.id === this._formOrder.value.listaPrecioId);
      if (isNil(priceList)) {
        this.toastr.warning('No tiene lista de precio', 'Alerta');
        return true;
      }
      const product = priceList.detalles.find((params) => params.productoId === this._form.value.product.id);
      if (isNil(product)) {
        this.toastr.warning('No se encontro el producto en la lista de precio', 'Alerta');
        return true;
      }
      const variante = product.variantes.find((params) => params.varianteId === this._formOrder.value.varianteId);
      if (isNil(product)) {
        this.toastr.warning('No se encontro la variante en la lista de precio', 'Alerta');
        return true;
      }
      if (isNil(variante)) {
        return;
      }
      precio = variante.precio;
    }
    const cantidad = this._formOrder.value.cantidad;
    const descuento = this._formOrder.value.descuento;
    this._actualAmount = (isBono ? 0.00 : round(precio * cantidad - descuento, 2));
  }

  bono(e) {
    this._isBono = e;
    this.amount(this._isBono);
  }

  addProduct() {
    const formOrder = this._formOrder.value;
    if ((isNil(formOrder.listaPrecioId) && !this._isBono) || isNil(formOrder.varianteId) || isNil(formOrder.cantidad)) {
      this.toastr.warning('Los campos en verde son obigatorios', 'Alerta');
      return;
    }
    const variantes = this._form.value.product.variantes;
    const variante = variantes.find((params) => params._id === formOrder.varianteId);
    this._formOrder.patchValue({ uniMedVarianteId: variante.unidadMedidaId });
    this._formOrder.patchValue({ variante });
    this._formOrder.patchValue({ productoId: this._form.value.product.id });
    this._formOrder.patchValue({ producto: this._form.value.product });
    if (this.subTotal()) {
      return;
    }
    this._formOrder.patchValue({ bonificacion: this._isBono });
    this._form.value.detalle.push(this._formOrder.value);
    this._formOrder.reset();
    this._form.patchValue({ product: {} });
    this.actualPage.emit('product');
  }

  subTotal() {
    const quantity = this._formOrder.value.cantidad;
    const descuento = this._formOrder.value.descuento;
    if (!this._isBono) {
      const priceList = this._priceList.find((params) => params.id === this._formOrder.value.listaPrecioId);
      if (isNil(priceList)) {
        this.toastr.warning('No tiene lista de precio', 'Alerta');
        return true;
      }
      const product = priceList.detalles.find((params) => params.productoId === this._form.value.product.id);
      if (isNil(product)) {
        this.toastr.warning('No se encontro el producto en la lista de precio', 'Alerta');
        return true;
      }
      const variante = product.variantes.find((params) => params.varianteId === this._formOrder.value.varianteId);
      if (isNil(product)) {
        this.toastr.warning('No se encontro la variante en la lista de precio', 'Alerta');
        return true;
      }
      this._formOrder.patchValue({ precio: variante.precio });
      this._formOrder.patchValue({ subTotal: this.round(((variante.precio * quantity) - descuento), 2)});
    } else {
      this._formOrder.patchValue({ precio: 0.00 });
      this._formOrder.patchValue({ subTotal: 0.00 });
    }
  }

  productList() {
    this.dialog.open(ProductListComponent,
      {
        context: {
          products: this._form.value.detalle
        }
      });
  }

round(num: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
  }

  goProducts() {
    this._form.patchValue({ product: {} });
    this.actualPage.emit('product');
  }

  get priceListName(): { id: string, nombre: string }[] {
    return this._priceListName;
  }

  get formOrder(): FormGroup {
    return this._formOrder;
  }

  get actualAmount(): number {
    return this._actualAmount;
  }

}
