import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Form } from 'src/app/lib/classes/form';
import { Place } from 'src/app/lib/interfaces/lugar';
import { isNil } from 'lodash';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends Form<any> implements OnInit {

  @ViewChild('map', { static: true }) mapaElement: ElementRef;
  private map: google.maps.Map;
  private lat: number;
  private lng: number;
  private _departamentos = [
    'Alta Verapaz',
    'Baja Verapaz',
    'Chimaltenango',
    'Chiquimula',
    'El Progreso',
    'Escuintla',
    'Guatemala',
    'Huehuetenango',
    'Izabal',
    'Jalapa',
    'Jutiapa',
    'Petén',
    'Quetzaltenango',
    'Quiché',
    'Retalhuleu',
    'Sacatepéquez',
    'San Marcos',
    'Santa Rosa',
    'Sololá',
    'Suchitepéquez',
    'Totonicapán',
    'Zacapa'
  ];
  constructor() {
    super();
  }

  ngOnInit() {
    this.posicion();
  }

  hereMap() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;
        this._form.patchValue({
          ubicacion: {
            longitud: pos.coords.longitude,
            latitud: pos.coords.latitude
          }
        });
        this.loadMap();
      });
    }
  }

  posicion() {
    if (!isNil(this._form.value.ubicacion.latitud) && !isNil(this._form.value.ubicacion.longitud)) {
      this.lat = this._form.value.ubicacion.latitud;
      this.lng = this._form.value.ubicacion.longitud;
      this.loadMap();
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          /*this._form.patchValue({
            ubicacion: {
              longitud: pos.coords.latitude,
              latitud: pos.coords.longitude
            }
          });*/
          this.lat = pos.coords.latitude;
          this.lng = pos.coords.longitude;
          this.loadMap();
        });
      }
    }
  }

  loadMap() {
    setTimeout(() => {
      if (navigator.geolocation) {
        const latLng = new google.maps.LatLng(this.lat, this.lng);
        const optionsMap: google.maps.MapOptions = {
          center: latLng,
          zoom: 17,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapaElement.nativeElement, optionsMap);
        const client: Place = {
          name: `${this._form.value.entidad.nombreCompleto}`,
          lat: this.lat,
          lng: this.lng
        };
        this.addMarker(client);
      }
    }, 0);
  }

  addMarker(marker: Place) {
    const latLng = new google.maps.LatLng(marker.lat, marker.lng);
    const pointer = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: latLng,
      draggable: true,
    });
    const contenido = `<b>${marker.name}</b>`;
    const infoWindow = new google.maps.InfoWindow({
      content: contenido
    });
    if (this._form.value.entidad.nombreCompleto) {
      google.maps.event.addDomListener(pointer, 'click', (coors) => {
        infoWindow.open(this.map, pointer);
      });
    }

    google.maps.event.addDomListener(pointer, 'drag', (coors) => {
      this._form.patchValue({
        ubicacion: {
          longitud: coors.latLng.lng(),
          latitud: coors.latLng.lat()
        }
      });
    });
  }

  get departamentos(): string[] {
    return this._departamentos;
  }
}
