import { Component, OnInit } from '@angular/core';
import { List } from 'src/app/lib/classes/list';
import { Route } from '../../lib/classes/route';
import { Res } from 'src/app/lib/rest/rest-api';
import { CoreService } from 'src/app/lib/services/core.service';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { LocalStorageService } from 'ngx-webstorage';
import { find, isNil } from 'lodash';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent extends List<Route> implements OnInit {

  private _disabled = true;
  constructor(private _core: CoreService, private router: Router, private toastr: NbToastrService, private storage: LocalStorageService) {
    super();
  }

  async ngOnInit() {
    this.load();
  }

  async load(offset = 0) {
    this._core.spinnerOn();
    try {
      const route: Res<Route> = await this._core.api.get('route').find('estado=0', `${this._core.user._id}/vendedor`)
        .toPromise() as Res<Route>;
      const data: any = route.res;
      data.inventario.forEach(element => {
        element.check = false;
      });
      this._data = data;
      this._core.spinnerOff();
    } catch (error) {
      this.toastr.danger('Problemas al conectarse con el servidor', 'Error', { duration: 0 });
    } finally {
      this._core.spinnerOff();
    }
  }

  private findFalse() {
    return find(this._data.inventario, { check: false });
  }

  checkValidate(product) {
    const index = this._data.inventario.indexOf(product);
    this._data.inventario[index].check = !product.check;
    if (isNil(this.findFalse())) {
      this._disabled = false;
    }
  }

  async validate() {
    if (isNil(this.findFalse())) {
      try {
        this._core.spinnerOn();
        await this._core.api.get('route').update(this._data, 'validar=true').toPromise();
        this.toastr.success('Ruta validada', 'Ruta');
        this.router.navigate(['/start/menu']);
        this.storage.store('haveRoute', true);
      } catch (error) {
        this.toastr.danger(error.error.error, 'Error', { duration: 0 });
      } finally {
        this._core.spinnerOff();
      }
    }
  }

  get disabled(): boolean {
    return this._disabled;
  }

}
