import { Injectable } from '@angular/core';
import { CoreService } from '../lib/services/core.service';
import { LocalStorageService } from 'ngx-webstorage';
import { round } from 'lodash';
import { CanActivate, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private _core: CoreService, private storage: LocalStorageService,
              private toastr: NbToastrService, private router: Router) {
  }

  async canActivate() {
    if (await this.validToken()) {
      return true;
    }

    return false;
  }


  async validToken() {
    const token = this.storage.retrieve('token');
    if (token !== 'token' && token && (token.length > 4)) {
      const now = round((new Date().getTime()) / 1000, 0);
      const exp = this._core.parseJwt(token).exp;
      const renovate = exp - 2000;

      if ((now >= renovate) && (now <= exp)) {
        const newToken: any = await this._core.api.get('login').find().toPromise();
        this.storage.store('token', newToken.token);
        this.validToken();
      }

      if (now > exp) {
        this._core.token = false;
        this.toastr.danger('El token a caducado', 'Token', { duration: 0 });
        this.router.navigate(['/']);
        return false;
      } else {
        this._core.token = true;
        return true;
      }
    } else {
      this._core.token = false;
      this.router.navigate(['/']);
      return false;
    }
  }


}
