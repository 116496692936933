import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  private _pages = 0;
  private _actualPage = 0;
  @Output() pageChanged = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  more() {
    if ((this._actualPage + 1) === this._pages) {
      return;
    }
    this._actualPage++;
    this.pageChanged.emit(this._actualPage * 10);
  }

  minus() {
    if (this._actualPage === 0) {
      return;
    }
    this._actualPage--;
    this.pageChanged.emit(this._actualPage * 10);
  }

  @Input() set pages(value: number) {
    this._pages = value;
  }

  get pages(): number {
    return this._pages;
  }

  get actualPage(): number {
    return this._actualPage;
  }

}
