<nb-card size="medium" class="m-0">
  <nb-card-header class="d-flex justify-content-between">
    <i class="fas fa-times" (click)="close()"></i>
    <span>Productos</span>
    <div>&nbsp;</div>
  </nb-card-header>
  <nb-card-body>
    <nb-list-item *ngFor="let product of products">
      <nb-card style="min-width: 300px;">
        <nb-card-body>
          <nb-badge *ngIf="product.bonificacion" text="Bonificación" status="warning" position="top left"></nb-badge>
          <nb-badge [text]="product.producto.codigo" status="info" position="top right"></nb-badge>
          <div class="d-flex">
            <div class="d-flex flex-column w-75">
              <span>{{product.producto.nombre}}</span>
              <small>{{product.variante.nombre}}</small>
              <small>
                {{product.cantidad}} X {{product.precio | number:'0.2-2'}} -
                {{product.descuento | number:'0.2-2'}} = {{product.subTotal | number:'0.2-2'}}
              </small>
            </div>
            <div class="d-flex justify-content-center align-items-center w-25">
              <button nbButton (click)="delete(product)" shape="semi-round" size="small" status="danger"><i
                  class="fas fa-trash"></i></button>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-list-item>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <h1>Q {{total | number:'0.2-2'}}</h1>
  </nb-card-footer>
</nb-card>