import { Injectable } from '@angular/core';
import { CoreService } from '../lib/services/core.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private _core: CoreService) { }
  resolve(): Promise<any> {
    this._core.api.add<any>('order', 'documento');
    this._core.api.add<any>('client', 'cliente');
    this._core.api.add<any>('product', 'producto');
    this._core.api.add<any>('priceList', 'lista-precio');
    this._core.api.add<any>('paymentMethod', 'metodo-pago');
    this._core.api.add<any>('configuration', 'configuracion');
    this._core.api.add<any>('payForm', 'forma-pago');
    this._core.api.add<any>('print', 'impresion');
    this._core.api.add<any>('user', 'usuario');
    this._core.api.add<any>('location', 'ubicacion');
    return Promise.resolve();
  }
}

