import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  private _body = '';
  private _color = 'warning';
  private _icon = 'fa-exclamation-triangle';

  constructor(private ref: NbDialogRef<boolean>) { }

  ngOnInit() {
  }

  ok() {
    this.ref.close(true);
  }

  no() {
    this.ref.close(false);
  }

  get body(): string {
    return this._body;
  }

  set body(value: string) {
    this._body = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

}
