<div class="d-flex justify-content-center align-items-center h-100">
  <form [formGroup]="form" (ngSubmit)="save()" class="d-flex justify-content-center">
    <nb-card size="large" class="w-95">
      <nb-card-header class="d-flex justify-content-between">
        <button type="submit" size="small" shape="semi-round" nbButton status="primary">
          <i class="fas fa-save"></i>
        </button>
        <h1>
          <i (click)="changeState()" [ngClass]="(active) ? 'text-success' : 'text-danger'" class="fas fa-user"></i>
        </h1>
        <button type="button" size="small" (click)="clear()" shape="semi-round" nbButton status="danger">
          <i class="fas fa-ban"></i>
        </button>
      </nb-card-header>
      <nb-card-body>
          <nb-badge [text]="active ? 'Activo' : 'Inactivo'" [status]="active ? 'success' : 'danger'"
          position="bottom left"></nb-badge>
        <app-head [form]="form" *ngIf="page === 'head'"></app-head>
        <app-map [form]="form" *ngIf="page === 'map'"></app-map>
        <app-picture [form]="form" *ngIf="page === 'picture'"></app-picture>
        <app-photo [form]="form" *ngIf="page === 'photo'"></app-photo>
      </nb-card-body>
      <nb-card-footer style="position: relative" class="d-flex justify-content-between">       
        <i (click)="left()" class="fas fa-chevron-left"></i>
        <i (click)="right()" class="fas fa-chevron-right"></i>
      </nb-card-footer>
    </nb-card>
  </form>
</div>