<form [formGroup]="form" class="d-flex justify-content-between flex-column h-100">
  <div formGroupName="ubicacion">
    <div>
      <div class="row">
        <div class="col">
          <div #map id="map"></div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <button nbButton fullWidth type="button" (click)="hereMap()" shape="semi-round" status="warning"><i
        class="fas fa-map-marker-alt"></i>Confirmar Ubicación</button>
      <!--h1><i (click)="hereMap()" class="fas fa-map-marker-alt"></i></h1-->
    </div>
    <div class="row form-group">
      <div class="col">
        <input type="text" status="success" formControlName="referencia" class="form-control" nbInput fullWidth
          placeholder="Referencia">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-6">
        <input type="string" status="success" formControlName="zona" class="form-control" nbInput fullWidth
          placeholder="Zona/Aldea">
      </div>
      <div class="col-6">
        <input type="text" status="success" formControlName="ciudad" class="form-control" nbInput fullWidth
          placeholder="Ciudad">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-12">
        <!-- <input type="text" status="success" formControlName="departamento" class="form-control" nbInput fullWidth
          placeholder="Departamento"> -->
        <nb-select status="success" placeholder="Departamento" formControlName="departamentoId" fullWidth>
          <nb-option *ngFor="let departamento of departamentos" [value]="departamento">{{departamento}}</nb-option>
        </nb-select>
      </div>
      <!-- <div class="col-6">
        <input type="text" status="success" formControlName="pais" class="form-control" nbInput fullWidth
          placeholder="Pais">
      </div> -->
    </div>
  </div>
</form>