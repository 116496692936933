import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LocalStorageService } from 'ngx-webstorage';

import { ResourceApi } from '../rest/rest-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
// http://idpc4604:5000/api/v1/
// http://138.197.214.214/api/v1/
// https://app.amazingdevapp.site/api/v1/
export class CoreService {
  private _api: ResourceApi;
  private _token = false;
  constructor(private http: HttpClient, private storage: LocalStorageService,
              private spinner: NgxSpinnerService, private toastr: NbToastrService, private router: Router) {
    this._api = new ResourceApi(this.http, this.storage, 'https://api.gapa.work/v1/');
    this.api.add<any>('login', 'login');
    this.api.add<any>('route', 'ruta');
  }

  spinnerOn() {
    this.spinner.show('mySpinner', {
      type: 'timer',
      size: 'large',
      bdColor: 'rgba(16,38,61, .8)',
      color: 'white'
    });
  }

  spinnerOff() {
    this.spinner.hide('mySpinner');
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  resetAuth() {
    setTimeout(() => {
      this._token = false;
      this.storage.store('token', 'token');
      this.storage.clear('user');
    }, 0);
  }

  get api(): ResourceApi {
    return this._api;
  }

  get token() {
    return this._token;
  }

  set token(value: boolean) {
    this._token = value;
  }

  get user() {
    return this.storage.retrieve('user');
  }

  get warehouseId() {
    return this.storage.retrieve('warehouse');
  }

  get routeId() {
    return this.storage.retrieve('route');
  }
}
