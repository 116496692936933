<div class="d-flex justify-content-center align-items-center h-100">
  <form [formGroup]="form">
    <nb-card size="small">
      <nb-card-header class="text-center">
        <h1><i class="fas fa-user-tie"></i></h1>
      </nb-card-header>
      <nb-card-body>
        <div class="form-group">
          <input type="text" status="success" formControlName="usuario" class="form-control" nbInput fullWidth placeholder="Usuario">
        </div>
        <div class="form-group">
          <input type="password" status="success" formControlName="password" nbInput fullWidth placeholder="Contraseña">
        </div>
        <div class="from-group">
          <button shape="semi-round" (click)="send()" nbButton fullWidth status="primary">
            <i class="fas fa-sign-in-alt"></i>
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>