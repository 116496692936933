<div class="d-flex justify-content-center align-items-center h-100">
  <nb-card size="large" class="m-0 w-95">
    <nb-card-header class="d-flex justify-content-between">
      <div>
        {{data?.nombre}}
      </div>
      <div>
        {{data?.fecha |  date: 'dd/MM/yyyy'}}
      </div>
      <button nbButton size="small" type="button" (click)="load()" shape="semi-round" status="info"><i class="fas fa-redo-alt"></i></button>
    </nb-card-header>
    <nb-card-body>
      <nb-list>
        <nb-list-item *ngFor="let product of data?.inventario" class="d-flex flex-column">
          <div class="relative">
            <!-- Bono: <ui-switch disabled size="small" [checked]="product.bonificacion"></ui-switch> -->
            <nb-badge *ngIf="product.bonificacion" text="BONIFICACION" status="success" position="top right"></nb-badge>
          </div>
          <div class="d-flex">
            <div class="w-75 d-flex flex-column">
              <div>{{product.producto.nombre}}</div>
              <div>{{product.nombre}}</div>
              <div>{{product.cantidadIni | number:'0.2-2'}}</div>
            </div>
            <div class="w-25 d-flex justify-content-end align-items-end">
              <ui-switch (valueChange)="checkValidate(product)" size="small" [checked]="product.check"></ui-switch>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton type="button" (click)="validate()" [disabled]="disabled" fullWidth
        status="primary">Validar</button>
    </nb-card-footer>
  </nb-card>
</div>