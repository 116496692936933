import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { EntityService, Res } from 'src/app/lib/rest/rest-api';
import { Order } from 'src/app/lib/classes/order';
import { isNil } from 'lodash';
import { CoreService } from 'src/app/lib/services/core.service';

@Component({
  selector: 'app-void-document',
  templateUrl: './void-document.component.html',
  styles: []
})
export class VoidDocumentComponent implements OnInit {

  private _documentId = '';
  private _rsc: EntityService<Order>;
  private _motivo = '';

  constructor(private ref: NbDialogRef<boolean>, private _core: CoreService, private toastr: NbToastrService) { }

  ngOnInit() {
  }

  async void() {
    if (!isNil(this._rsc) && (!isNil(this._documentId) || this._documentId !== '')) {
      this._core.spinnerOn();
      try {
        const uptadeDoc: Res<Order> = await this._rsc.post(`${this._documentId}/anular?motivoAnulacion=${this._motivo}`)
        .toPromise() as Res<Order>;
        if (!isNil(uptadeDoc)) {
          this._core.spinnerOff();
          this.ref.close(true);
        }
      } catch (error) {
        this._core.spinnerOff();
        this.toastr.warning(error.error.error, 'Atención', { duration: 0 });
      }
    }
  }

  close() {
    this.ref.close(false);
  }

  get motivo(): string {
    return this._motivo;
  }

  set motivo(motivo: string) {
    this._motivo = motivo;
  }

  get documentId(): string {
    return this._documentId;
  }

  set documentId(id: string) {
    this._documentId = id;
  }

  get rsc(): EntityService<Order> {
    return this._rsc;
  }

  set rsc(rsc: EntityService<Order>) {
    this._rsc = rsc;
  }

}
