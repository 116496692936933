<div class="d-flex justify-content-center align-items-center h-100">
  <nb-card size="large" class="m-0 w-95">
    <nb-card-header>
      <div class="row d-flex justify-content-between">
        <div class="col"  *ngIf="rolUser">
          <div class="form-group">
            <nb-select (ngModelChange)="load()" [(ngModel)]="sellerId" placeholder="Vendedores" status="success" fullWidth>
              <nb-option value="TODOS">TODOS</nb-option>
              <nb-option *ngFor="let seller of sellersList" [value]="seller._id">
                {{seller.entidad?.nombreCompleto}}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <div class="col-1">
          <button nbButton size="small" shape="semi-round" type="button" status="primary" (click)="print(data)"><i
            class="fas fa-print"></i></button>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <nb-list>
        <nb-list-item *ngFor="let summary of data">
          <h3>Productos</h3>
          <nb-flip-card *ngFor="let product of summary.resumenProductos">
            <nb-card-front>
              <nb-card size="tiny">
                <nb-card-body>
                  <nb-badge *ngIf="product.bonificacion" text="Bono" status="info" position="top lefth"></nb-badge>
                  <span>{{product.producto}}</span>
                  <nb-badge class="fz-m" text="Q {{product.total | number: '0.2-2'}}" status="success"
                    position="top right"></nb-badge>
                </nb-card-body>
              </nb-card>
            </nb-card-front>
            <nb-card-back>
              <nb-card size="tiny">
                <nb-card-body>
                  <table class="w-100 table table-hobber">
                    <thead>
                      <tr>
                        <th>Variante</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let variant of product.variantes">
                        <td>{{variant.nombre}}</td>
                        <td class="text-center">{{variant.cantidad}}</td>
                      </tr>
                    </tbody>
                  </table>
                </nb-card-body>
              </nb-card>
            </nb-card-back>
          </nb-flip-card>
          <h6 class="d-flex justify-content-end">Total: Q.{{summary.totalProd | number: '0.2-2'}}</h6>
          <h3>Pagos Recibidos</h3>
          <nb-card *ngFor="let pay of summary.resumenDetallePagos">
            <nb-card-body>
              {{pay.nombre}}
              <nb-badge class="fz-m" text="Q {{pay.valor | number: '0.2-2'}}" status="success" position="bottom right">
              </nb-badge>
            </nb-card-body>
          </nb-card>
          <h6 class="d-flex justify-content-end">Total: Q.{{summary.totalPagos | number: '0.2-2'}}</h6>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</div>