import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from './lib/services/core.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private router: Router, private _core: CoreService, private storage: LocalStorageService) {
    // this._core.validToken();
  }

  menu() {
    this.router.navigate(['/start/menu']);
  }

  exit() {
    this.storage.clear('token');
    this.storage.clear('user');
    this.storage.clear('route');
    this.storage.clear('warehouse');
    this.storage.clear('haveRoute');
    this.router.navigate(['/']);
  }

  get core() {
    return this._core;
  }


}
