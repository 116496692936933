<div class="d-flex justify-content-center align-items-center h-100">
  <nb-card size="large" class="m-0">
    <nb-card-header class="d-flex justify-content-between">
      <i class="fas fa-times" (click)="close()"></i>
      <span>Total del Pedido</span>
      <div>&nbsp;</div>
    </nb-card-header>
    <nb-card-body class="min-w">
      <div class="row">
        <div class="col text-center">
          <h1>Q {{totalPedido | number:'0.2-2'}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
              <h6>Descuento</h6>
            <input [(ngModel)]="descuento" class="text-right" type="number" nbInput fullWidth
              placeholder="Descuento">
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col d-flex justify-content-center">
              <button nbButton fullWidth type="button" (click)="goPay()" shape="semi-round" status="success"><i
                class="fas fa-money-bill-alt"></i>Aplicar</button>
          </div>
        </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <h1 class="text-primary">Q {{total | number:'0.2-2'}}</h1>
    </nb-card-footer>
  </nb-card>
</div>