<nb-card size="medium" class="m-0">
  <nb-card-header class="d-flex justify-content-between">
    <i class="fas fa-times" (click)="close()"></i>
    <span>Pagos</span>
    <div>&nbsp;</div>
  </nb-card-header>
  <nb-card-body>
    <nb-list-item *ngFor="let payment of payments">
      <nb-card style="min-width: 300px;">
        <nb-card-body>
          <div class="d-flex">
            <div class="d-flex flex-column w-75">
              <span>{{payment.metodoPago.nombre}}</span>
              <small>
                {{payment.banco}} {{payment.referencia}} {{payment.valor | number: '0.2-2'}}
              </small>
            </div>
            <div class="d-flex justify-content-center align-items-center w-25">
              <button nbButton (click)="delete(payment)" shape="semi-round" size="small" status="danger"><i
                  class="fas fa-trash"></i></button>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-list-item>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <h1>Q {{total | number:'0.2-2'}}</h1>
  </nb-card-footer>
</nb-card>