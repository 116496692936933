import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { List } from 'src/app/lib/classes/list';
import { Client } from 'src/app/lib/classes/client';
import { CoreService } from 'src/app/lib/services/core.service';
import { Res } from 'src/app/lib/rest/rest-api';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent extends List<Client> implements OnInit {

  private _form: FormGroup;
  @Output() actualPage = new EventEmitter();
  constructor(private _core: CoreService, private toastr: NbToastrService, private router: Router, private storage: LocalStorageService) {
    super();
    this._filtros = [
      { value: 'codigo', label: 'Código' },
      { value: 'idenFiscal', label: 'NIT' },
      { value: 'idenPersonal', label: 'DPI' },
      { value: 'nombreCompleto', label: 'Nombre' }
    ];
    this._rsc = this._core.api.get('client');
  }

  ngOnInit() {
    this.load();
  }

  filterBy(filter) {
    if (filter[1].length) {
      this._filterBy = `&${filter[0]}=${filter[1]}`;
    } else {
      this._filterBy = '';
    }
    this.load();
  }

  async load(offset = 0) {
    this._core.spinnerOn();
    try {
      const clients: Res<Client> = await this._rsc.find(`offset=${offset}&limit=10${this._filterBy}&vendedorId=${this.storage.retrieve('user')._id}`).toPromise() as Res<Client>;
      this._data = clients.res;
      this._pages = Math.ceil(clients.numReg / 10);
      this._core.spinnerOff();
    } catch (error) {
      this._core.spinnerOff();
      this.toastr.danger('Problemas al conectarse con el servidor', 'Error', { duration: 0 });
    }
  }

  addNew() {
    this.router.navigate(['/client/maintenance/new']);
  }

  setClient(cliente: Client) {
    this._form.patchValue({
      cliente,
      clienteId: cliente.id
    });
    this.actualPage.emit('product');
  }

  get form(): FormGroup {
    return this._form;
  }

  @Input() set form(value: FormGroup) {
    this._form = value;
  }

}
