import { FormGroup } from '@angular/forms';
import { Input } from '@angular/core';
import { EntityService, Base } from '../rest/rest-api';

export abstract class Form<T extends Base> {
  protected _form: FormGroup;
  protected _rsc: EntityService<T>;
  protected _id: string;

  constructor() { }

  get form(): FormGroup {
    return this._form;
  }

  @Input() set form(value: FormGroup) {
    this._form = value;
  }

}
