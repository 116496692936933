import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/lib/classes/form';
import { Order, Configuration, PayForm } from 'src/app/lib/classes/order';
import { FormBuilder, Validators } from '@angular/forms';
import { CoreService } from 'src/app/lib/services/core.service';
import { EntityService, Res } from 'src/app/lib/rest/rest-api';
import { LocalStorageService } from 'ngx-webstorage';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-new-edit',
  templateUrl: './new-edit.component.html',
  styleUrls: ['./new-edit.component.scss']
})
export class NewEditComponent extends Form<Order> implements OnInit {

  private _actualPage = 'client';
  protected _rscConfiguration: EntityService<any>;
  private _rscPayForm: EntityService<PayForm>;
  private _rscLocation: EntityService<any>;
  private lat: number;
  private lng: number;
  constructor(
    private builder: FormBuilder,
    private _core: CoreService,
    private storage: LocalStorageService,
    private tsr: NbToastrService
  ) {
    super();
    this._form = this.builder.group({
      serie: [null, Validators.required],
      correlativo: [null, Validators.required],
      fecha: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      flagRuta: [true, Validators.required],
      cliente: null,
      clienteId: [null, Validators.required],
      formaPago: null,
      formaPagoId: [null, Validators.required],
      rutaId: [this._core.routeId, Validators.required],
      bodegaId: [this._core.warehouseId, Validators.required],
      detalle: [[]],
      detallePagos: [[]],
      impuesto: null,
      impuestoId: [null, Validators.required],
      tipoMovimiento: null,
      tipoMovimientoId: [null, Validators.required],
      vendedorId: [null, Validators.required],
      subTotal: [null, Validators.required],
      descuento: 0,
      product: []
    });
    this._rscConfiguration = this._core.api.get('configuration');
    this._rscPayForm = this._core.api.get('payForm');
    this._rscLocation = this._core.api.get('location');
    this._form.patchValue({vendedorId: this.storage.retrieve('user')._id});
  }

  async ngOnInit() {
    try {
      this._core.spinnerOn();
      const configuration: Res<Configuration> = await this._rscConfiguration.find().toPromise() as Res<Configuration>;
      this._form.patchValue({ impuestoId: configuration.res.impuestoRutaId });
      this._form.patchValue({ tipoMovimientoId: configuration.res.tipoMovimientoRutaId });
      const configPayform: Res<PayForm> = await this._rscPayForm.find().toPromise() as Res<PayForm>;
      this._form.patchValue({formaPagoId: configPayform.res[0]._id});
      this.hereMap();
    } catch (error) {
      console.log(error);
    } finally {
      this._core.spinnerOff();
    }
  }

  hereMap() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (pos) => {
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;
        try {
          await this._rscLocation.insert({
            usuarioId: this._form.value.vendedorId,
            fecha: new Date(),
            longitud: this.lng,
            latitud: this.lat
          }).toPromise();
        } catch (error) {
          console.error(error);
        }
      }, (error) => {
        if (error.code === 1) {
          this.tsr.danger('Debe activar la ubicación para la aplicación', 'Ubicación');
        } else {
          this.tsr.danger(`Error ${error.code}, ${error.message}`);
        }
      });
    }
  }

  setPage(page: string) {
    this._actualPage = page;
  }

  get actualPage(): string {
    return this._actualPage;
  }

}
