import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/lib/services/core.service';
import { Router } from '@angular/router';

interface Opcion {
  title: string;
  icon: string;
  url: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  private _opciones: Opcion[] = [];
  constructor(private router: Router, private _core: CoreService) {
    // this._core.validToken();
    this._opciones = [{
      title: 'Clientes',
      icon: 'fa-users',
      url: '/client/maintenance'
    }, {
      title: 'Pedido',
      icon: 'fa-box',
      url: '/order/maintenance/new'
    }, {
      title: 'Lista Pedido',
      icon: 'fa-clipboard-list',
      url: '/order/maintenance'
    }, {
      title: 'Resumen Venta',
      icon: 'fa-boxes',
      url: '/order/maintenance/summary'
    }];
  }

  ngOnInit() {
    if (this._core.user.role !== 'VENTAS_ROLE') {
      this._opciones.push({
        title: 'Liquidacion',
        icon: 'fa-calculator',
        url: '/settlement/maintenance'
      })
    }
  }

  go(url: string) {
    this.router.navigate([url]);
  }

  get opciones(): Opcion[] {
    return this._opciones;
  }

}
