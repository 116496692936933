import { Component, OnInit } from '@angular/core';
import { List } from 'src/app/lib/classes/list';
import { SummaryOrder } from 'src/app/lib/classes/order';
import { CoreService } from 'src/app/lib/services/core.service';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { Res, EntityService } from 'src/app/lib/rest/rest-api';
import { LocalStorageService } from 'ngx-webstorage';
import { User } from 'src/app/public/public.service';
import { find } from 'lodash';

@Component({
  selector: 'app-summary-pedido-user',
  templateUrl: './summary-pedido-user.component.html',
  styleUrls: ['./summary-pedido-user.component.scss']
})
export class SummaryPedidoUserComponent extends List<SummaryOrder> implements OnInit {

  private _sellerId = '';
  private _sellersList: User[] = [];
  private _srcUser: EntityService<User>;
  private _localUser: any;
  private _vendedor = '';

  constructor(private _core: CoreService, private toastr: NbToastrService, private router: Router, private storage: LocalStorageService) {
    super();
    this._rsc = this._core.api.get('order');
    this._srcUser = this._core.api.get('user');
    this._localUser = this.storage.retrieve('user');
  }

  async ngOnInit() {
    if (this._localUser.role !== 'VENTAS_ROLE') {
      const sellers: User = await this._srcUser.findById(this._localUser._id).toPromise();
      this._sellersList = sellers.usuarios;
    } else {
      this.load();
    }
  }

  load(offset = 0) {
    this._core.spinnerOn();
    setTimeout(async () => {
      try {
        if (this._sellerId !== '') {
          if (this._sellersList.length > 0) {
            const seller = find(this._sellersList, {'_id': this._sellerId});
            if (seller) {
              this._vendedor = seller.entidad.nombreCompleto;
            } else {
              this._vendedor = 'TODOS';
            }
          }
        } else {
          this._vendedor = this._localUser.usuario;
        }
        const orders: Res<SummaryOrder> =
          await this._rsc.findById(this._localUser._id,
            `resumen${'?fecha=' + new Date().setHours(0, 0, 0, 0) + (this._sellerId !== '' ? '&vendedorId=' + this._sellerId : '')}`)
            .toPromise() as Res<SummaryOrder>;
        this._data = orders.res;
        this._pages = Math.ceil(orders.numReg / 10);
        this._core.spinnerOff();
      } catch (error) {
        this.toastr.danger('Problemas al conectarse con el servidor', 'Error', { duration: 0 });
      } finally {
        this._core.spinnerOff();
      }
    }, 0);
  }

  print(resumen: SummaryOrder) {
    this._core.spinnerOn();
    const objeto = JSON.stringify(resumen);
    try {
      const url =
        encodeURI(`https://api.gapa.work/v1/impresion/123/resumen-ventas?vendedor=${this._vendedor}&resumen=${objeto}`);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `5d35365619b48b398435f843.pdf`);
      link.click();
      this.router.navigate(['/order/maintenance/summary']);
    } catch (error) {
      this.toastr.danger(error.error.errores, 'Error');
    } finally {
      this._core.spinnerOff();
    }
  }

  get sellerId(): string {
    return this._sellerId;
  }

  set sellerId(id: string) {
    this._sellerId = id;
  }

  get sellersList(): Array<any> {
    return this._sellersList;
  }

  get rolUser(): boolean {
    return this._localUser.role !== 'VENTAS_ROLE';
  }
}
