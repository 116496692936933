<div class="d-flex align-items-center justify-content-between">
  <i [nbPopover]="template" nbPopoverPlacement="bottom" class="fas fa-search"></i>
  <input [(ngModel)]="searchBy" (keydown)="fiterByEnter($event)" [type]="typeText" class="w-75" nbInput fullWidth shape="round" [placeholder]="title">
  <i class="fas fa-check" (click)="filter()"></i>
</div>
<ng-template #template let-data>
  <nb-card class="popover-card">
    <nb-card-body>
      <nb-radio-group [(ngModel)]="check">
        <nb-radio *ngFor="let filtro of filtros" [value]="filtro.value">
          {{ filtro.label }}
        </nb-radio>
      </nb-radio-group>
    </nb-card-body>
  </nb-card>
</ng-template>