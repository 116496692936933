<nb-layout>
  <nb-layout-header fixed="true">
    <div class="d-flex justify-content-between w-100" *ngIf="core.token">
      <i (click)="menu()" class="fas fa-bars ml-2 fa-lg"></i>
      <i (click)="exit()" class="fas fa-door-open mr-2 fa-lg"></i>
    </div>
  </nb-layout-header>
  <nb-layout-column class="p-0">
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
<ngx-spinner name="mySpinner"></ngx-spinner>