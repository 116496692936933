import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentMethod } from 'src/app/lib/classes/order';
import { Form } from 'src/app/lib/classes/form';
import { CoreService } from 'src/app/lib/services/core.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { Res } from 'src/app/lib/rest/rest-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { find, isNil, round } from 'lodash';
import { PaymentListComponent } from '../payment-list/payment-list.component';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent extends Form<PaymentMethod> implements OnInit {

  private _paymentMethod: PaymentMethod[];
  private _formPayment: FormGroup;
  private _paymentType: PaymentMethod;
  private _actualAmount = 0;

  @Output() actualPage = new EventEmitter();
  constructor(private _core: CoreService, private toastr: NbToastrService, private builder: FormBuilder, private dialog: NbDialogService) {
    super();
    this._rsc = this._core.api.get('paymentMethod');
    this._formPayment = this.builder.group({
      metodoPagoId: [null, Validators.required],
      metodoPago: [null],
      banco: [null, Validators.required],
      referencia: [null],
      valor: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.loadPaymentMethod();
    this.loadAmountPay();
  }

  goProducts() {
    this.actualPage.emit('product');
  }

  validateAmountPay(amoutn = 0) {
    const amountProducts = this.form.value.detalle.reduce((total, detail) => total += round(detail.subTotal, 2), 0);
    const amountPament = this.form.value.detallePagos.reduce((total, detail) => total += round(detail.valor, 2), 0);
    if (amountProducts < 0) {
      this.toastr.warning('El monto adeudado no puede ser negativo', 'Alerta');
      return true;
    }
    if (amountPament < 0) {
      this.toastr.warning('El monto pagado no puede ser negativo', 'Alerta');
      return true;
    }
    if (round(amountProducts) < (round(amountPament + amoutn))) {
      this.toastr.warning('No puede pagar un monto mayor al adeudado', 'Alerta');
      return true;
    }
  }

  loadAmountPay(redirect = false) {
    const amountProducts = (this.form.value.detalle.reduce((total, detail) => total += round(detail.subTotal, 2), 0) -
    (!isNil(this._form.value.descuento) && this._form.value.descuento > 0 ? this._form.value.descuento : 0));
    const amountPament = this.form.value.detallePagos.reduce((total, detail) => total += round(detail.valor, 2), 0);
    this._actualAmount = round((amountProducts - amountPament), 2);
    if (redirect && this._actualAmount === 0) {
      this.actualPage.emit('summary');
    }
  }

  private async loadPaymentMethod() {
    this._core.spinnerOn();
    try {
      const paymentMethod: Res<PaymentMethod[]> = await this._rsc.find().toPromise() as Res<PaymentMethod[]>;
      this._paymentMethod = paymentMethod.res;
    } catch (error) {
      console.log(error);
    } finally {
      this._core.spinnerOff();
    }
  }

  paymentList() {
    const paymentList = this.dialog.open(PaymentListComponent,
      {
        context: {
          payments: this._form.value.detallePagos
        }
      }).onClose.subscribe(() => {
        this.loadAmountPay();
        paymentList.unsubscribe();
      });
  }

  addPayment() {
    const payForm = this._formPayment.value;
    if (this._paymentType.nombre === 'Deposito') {
      if (isNil(payForm.banco) || isNil(payForm.referencia)) {
        this.toastr.warning('Los campos en VERDE son obligatorios');
        return;
      }
    }
    if (isNil(payForm.metodoPagoId) || isNil(payForm.valor) || (payForm.valor < 0)) {
      this.toastr.warning('Los campos en VERDE son obligatorios');
      return;
    }
    if (!this.validateAmountPay(payForm.valor)) {
      this._form.value.detallePagos.push(this._formPayment.value);
      this._formPayment.reset();
    }
    this.loadAmountPay(true);
  }

  payment(id: string) {
    this._paymentType = find(this._paymentMethod, { _id: id });
    this._formPayment.patchValue({ metodoPago: this._paymentType });
  }

  async summary() {
    const amountProducts = (this.form.value.detalle.reduce((total, detail) => total += round(detail.subTotal, 2), 0) -
    (!isNil(this._form.value.descuento) && this._form.value.descuento > 0 ? this._form.value.descuento : 0));
    const amountPament = this.form.value.detallePagos.reduce((total, detail) => total += round(detail.valor, 2), 0);

    if ((round(amountProducts, 2)) !== (round(amountPament, 2))) {
      this.toastr.warning('El monto por pagar no esta completo', 'Alerta');
      return;
    }
    this.actualPage.emit('summary');
  }

  get paymentMethod(): PaymentMethod[] {
    return this._paymentMethod;
  }

  get formPayment(): FormGroup {
    return this._formPayment;
  }

  get paymentType(): string {
    if (!isNil(this._paymentType)) {
      return this._paymentType.nombre;
    }
  }

  get actualAmount(): number {
    return this._actualAmount;
  }

}
