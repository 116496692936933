import { NgModule } from '@angular/core';
import { EntityModule } from './entity/entity.module';
import { RoutingModule } from './routing.module';

const modules = [
  EntityModule,
  RoutingModule
];

const services = [];
@NgModule({
  declarations: [

  ],
  imports: [
    ...modules
  ], providers: [
    ...services
  ]
})
export class ClientModule { }
