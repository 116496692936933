import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CheckRouteGuard implements CanActivate {
  constructor(
    private toastr: NbToastrService,
    private storage: LocalStorageService
  ) { }

  async canActivate() {
    if (this.storage.retrieve('haveRoute')) {
      return true;
    } else {
      this.toastr.warning('Ruta no validada o sin productos.', 'Ruta', { duration: 3000 });
      return false;
    }
  }
}
