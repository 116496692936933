import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/lib/classes/form';
import { Client } from 'src/app/lib/classes/client';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss']
})
export class HeadComponent extends Form<Client> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
