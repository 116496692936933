import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
  path: 'login',
  loadChildren: './public/public.module#PublicModule',
}, {
  path: 'start/menu',
  loadChildren: './start/start.module#StartModule',
}, {
  path: 'client/maintenance',
  loadChildren: './client/client.module#ClientModule',
}, {
  path: 'order/maintenance',
  loadChildren: './order/order.module#OrderModule',
}, {
  path: 'settlement/maintenance',
  loadChildren: './settlement/settlement.module#SettlementModule',
}, {
  path: '',
  redirectTo: 'login',
  pathMatch: 'full'
}];


@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
