import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PedidoModule } from './pedido/pedido.module';
import { RoutingModule } from './routing.module';

const modules = [
  PedidoModule,
  RoutingModule
];

const services = [];

@NgModule({
  declarations: [],
  imports: [
    ...modules
  ], providers: [
    ...services
  ]
})
export class OrderModule { }
