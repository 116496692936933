import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { AuthGuardService } from '../guards/auth-guard.service';
import { CheckListGuard } from '../guards/check-list.guard';
import { CheckListComponent } from './check-list/check-list.component';

const routes = [{
  path: 'start/menu', children: [{
    path: '',
    component: MenuComponent,
    canActivate: [AuthGuardService, CheckListGuard]
  }]
}, {
  path: 'start/check-list', children: [{
    path: '',
    component: CheckListComponent,
    canActivate: [AuthGuardService]
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }
