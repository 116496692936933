import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/lib/classes/form';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss']
})
export class PictureComponent extends Form<any> implements OnInit {

  private _picture = '';
  constructor(private toastr: NbToastrService) {
    super();
  }

  ngOnInit() {
    this._picture = (this._form.value.foto) ? this._form.value.foto : '../../../../../assets/img/user.png';
  }

  verFoto(evt) {
    const file = evt.srcElement.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this._picture = 'data:image/png;base64,' + btoa(e.target.result);
    this._form.patchValue({ foto: 'data:image/png;base64,' + btoa(e.target.result) });
    this.toastr.success('Foto tomada', 'Foto');
  }

  get picture() {
    return this._picture;
  }

}
