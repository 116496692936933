<div class="d-flex justify-content-center align-items-center h-100">
  <form [formGroup]="formOrder" (ngSubmit)="addProduct()">
    <nb-card size="large" class="m-0">
      <nb-card-header class="d-flex justify-content-between">
        <i class="fas fa-chevron-left" (click)="goProducts()"></i>
        <div>{{form.value.product.codigo}} - {{form.value.product.nombre}}</div>
        <div>&nbsp;</div>
      </nb-card-header>
      <nb-card-body class="min-w">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <nb-select (ngModelChange)="amount()" formControlName="listaPrecioId" placeholder="Lista de precio"
                    status="success" fullWidth>
                    <nb-option *ngFor="let list of priceListName" [value]="list.id">
                      {{list.nombre}}
                    </nb-option>
                  </nb-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <nb-select (ngModelChange)="amount()" formControlName="varianteId" placeholder="Variante"
                    status="success" fullWidth>
                    <nb-option *ngFor="let variante of form.value.product.variantes" [value]="variante._id">
                      {{variante.nombre}}
                    </nb-option>
                  </nb-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <input (focusout)="amount()" formControlName="cantidad" class="text-right" type="number" nbInput
                    fullWidth status="success" placeholder="Cantidad">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="descuento" class="label">Descuento:</label>
                  <input (focusout)="amount()" formControlName="descuento" id="descuento"class="text-right" type="number" nbInput
                    fullWidth status="warning" placeholder="Descuento">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-center">
                <span></span>
              </div>
              <div class="col-6 text-right">
                Bono
                <ui-switch (valueChange)="bono($event)" size="small"></ui-switch>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <h3>Q {{actualAmount | number:'0.2-2'}}</h3>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between align-items-center">
        <button nbButton type="button" (click)="productList()" shape="semi-round" status="info"><i
            class="fas fa-bars"></i></button>

        <button nbButton type="submit" shape="semi-round" status="primary"><i class="fas fa-plus"></i></button>
      </nb-card-footer>
    </nb-card>
  </form>
</div>