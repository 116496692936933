<div class="d-flex justify-content-center align-items-center h-100">
  <nb-card size="large" class="m-0 w-95">
    <nb-card-header>
      <app-search title="Cliente" typeText="text" [filtros]="filtros" check="name" like="true" (search)="load($event)">
      </app-search>
    </nb-card-header>
    <nb-card-body>
      <nb-list>
        <nb-list-item *ngFor="let product of data" (click)="setProduct(product)">
          <nb-card (click)="setProduct(product)">
            <nb-card-body class="d-flex">
              <nb-badge [text]="product.codigo" status="info" position="top left"></nb-badge>
              <nb-badge text="{{product.activo ? 'Activo' : 'Inactivo'}}"
                status="{{product.activo ? 'success' : 'danger'}}" position="top right"></nb-badge>
              <div class="row">
                <div class="col-6">
                  <img width="100%" src="../../../../../assets/img/{{product.id}}.jpeg" />
                </div>
                <div class="col-6">
                  <small>{{product.nombre}}</small>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-10">
          <app-paginator (pageChanged)='load($event)' [pages]="pages"></app-paginator>
        </div>
        <div class="col-2">
          <button type="button" [nbPopover]="templateOptions" nbButton shape="round" size="small" status="primary">
            <i class="fas fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
<ng-template #templateOptions>
  <div class="row">
    <div class="col">&nbsp;</div>
  </div>
  <div class="row">
    <div class="col">
      <button type="button" (click)="productList()" nbButton shape="round" status="info" fullWidth>
        <i class="fas fa-bars"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">&nbsp;</div>
  </div>
  <!--<div class="row">
    <div class="col">
      <button type="button" (click)="goDiscount()" nbButton shape="round" status="warning" fullWidth>
        <i class="fas fa-money-bill-wave"></i>
      </button>
    </div>
  </div>-->
  <div class="row">
    <div class="col">&nbsp;</div>
  </div>
  <div class="row">
    <div class="col">
      <button type="button" (click)="goPay()" nbButton shape="round" status="success" fullWidth>
        <i class="fas fa-money-bill-alt"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">&nbsp;</div>
  </div>
</ng-template>