import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CoreService } from '../lib/services/core.service';
import { NbToastrService } from '@nebular/theme';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(private _core: CoreService, private router: Router, private toastr: NbToastrService) { }
  canActivate() {
    if (isNil(this._core.routeId)) {
      this.toastr.warning('No tiene ruta asignada', 'Ruta');
      return false;
    } else {
      return true;
    }
  }
}
