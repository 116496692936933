import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/lib/classes/form';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { AlertComponent } from 'src/app/lib/components/alert/alert.component';
import { CoreService } from 'src/app/lib/services/core.service';
import { Client } from 'src/app/lib/classes/client';
import { Res, EntityService } from 'src/app/lib/rest/rest-api';
import { isNil } from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';
import { AgrupadorMOV } from 'src/app/lib/classes/product';
import { PayForm } from 'src/app/lib/classes/order';

@Component({
  selector: 'app-new-edit',
  templateUrl: './new-edit.component.html',
  styleUrls: ['./new-edit.component.scss']
})
export class NewEditComponent extends Form<Client> implements OnInit {

  private _page = 'head';
  private _active = true;
  private _rscAgrupadorMov: EntityService<any>;
  private _rscPayForm: EntityService<any>;
  private _agrupadorMov: AgrupadorMOV[] = [];
  private _payForm: PayForm[] = [];
  constructor(private builder: FormBuilder, private router: Router,
              private dialog: NbDialogService, private toastr: NbToastrService,
              private _core: CoreService, private route: ActivatedRoute,
              private storage: LocalStorageService) {
    super();
    this._rsc = this._core.api.get('client');
    this._rscAgrupadorMov = this._core.api.get('agrupador-mov');
    this._rscPayForm = this._core.api.get('pay-form');
    this._form = this.builder.group({
      id: null,
      codigo: [null],
      nombreNegocio: [null],
      entidadId: [null],
      foto: [null, Validators.required],
      entidad: this.builder.group({
        idenPersonal: null,
        idenFiscal: null,
        nombre1: [null, Validators.required],
        nombre2: [null],
        apellido1: [null, Validators.required],
        apellido2: [null],
        nombreCompleto: [null],
        direccion: [null],
        telefono: [null],
        movil: [null],
        correo: [null],
        sitioWeb: [null],
        sexo: [false, Validators.required],
        cargo: [null],
        compania: [false, Validators.required],
      }),
      formaPagoId: ['5d2007365c65af0ef4a6ca00'],
      formaPago: [[]],
      dias: [0, Validators.required],
      agrupadorMovId: ['5d2007d15c65af0ef4a6ca01'],
      agrupadorMov: [[]],
      vendedorId: this.storage.retrieve('user')._id,
      rutaId: this.storage.retrieve('route'),
      ubicacion: this.builder.group({
        referencia: [null, Validators.required],
        zona: [null, Validators.required],
        ciudad: [null, Validators.required],
        departamentoId: ['Guatemala', Validators.required],
        departamento: [null],
        paisId: ['Guatemala', Validators.required],
        pais: ['Guatemala', Validators.required],
        longitud: [null, Validators.required],
        latitud: [null, Validators.required],
      }),
      clasificacion: [null],
      activo: [true, Validators.required],
    });
    this._id = this.route.snapshot.paramMap.get('id');
  }

  async ngOnInit() {
    Promise.all([this._agrupadorMov = await this._rscAgrupadorMov.find('nombre=Clientes').toPromise(),
    this._payForm = await this._rscPayForm.find('nombre=Contado').toPromise()]).then(resp => {
      const agrupador: any = resp[0];
      const payform: any = resp[1];
      this._form.patchValue({agrupadorMovId: agrupador.res[0]._id, formaPagoId: payform.res[0]._id});
    });
    if (!isNil(this._id)) {
      try {
        this._core.spinnerOn();
        const user: Res<Client> = await this._rsc.findById(this._id).toPromise() as Res<Client>;
        this._active = user.res.activo;
        this._form.patchValue(user.res);
        this._core.spinnerOff();
      } catch (error) {
        this._core.spinnerOff();
      }
    }
  }

  left() {
    switch (this._page) {
      case 'head':
        this.router.navigate(['/client/maintenance']);
        break;
      case 'map':
        this._page = 'head';
        break;
      case 'picture':
        this._page = 'map';
        break;
      case 'photo':
        this._page = 'picture';
        break;
      default:
        this.router.navigate(['/client/maintenance']);
        break;
    }
  }

  right() {
    switch (this._page) {
      case 'head':
        if (isNil(this._form.value.id)) {
          this.toastr.warning('', 'CONFIRME LA UBICACIÓN');
        }
        this._page = 'map';
        break;
      case 'map':
        if (!isNil(this._form.value.ubicacion) && (isNil(this._form.value.ubicacion.longitud) ||
          isNil(this._form.value.ubicacion.latitud))) {
          this.toastr.danger('', 'DEBE CONFIRMAR UBICACION', {duration: 6000});
        } else {
          this._page = 'picture';
        }
        break;
      case 'picture':
        this._page = 'head';
        break;
      default:
        this.router.navigate(['/client/maintenance']);
        break;
    }
  }

  async save() {
    if (this._form.invalid) {
      this.toastr.warning('Los campos de color VERDE y FOTO son obligatorios.', 'Alerta', { duration: 4000 });
      return;
    }
    if (await this.validate(this._form.value)) {
      return;
    }
    try {
      this._core.spinnerOn();
      const opt = (isNil(this._id) ? 'insert' : 'update');
      await this._rsc[opt](this._form.value).toPromise();
      this.toastr.success(`Cliente ${isNil(this._id) ? 'creado' : 'actualizado'}`, 'Cliente');
      this.router.navigate(['/client/maintenance']);
    } catch (error) {
      this.toastr.danger('Error al crear el cliente', 'Cliente');
    } finally {
      this._core.spinnerOff();
    }
  }

  validate(client: Client): Promise<boolean> {
    return new Promise( resolve => {
      let withError = false;
      const duration = 5000;
      if (isNil(client.ubicacion.latitud) || isNil(client.ubicacion.latitud)) {
        this.toastr.danger('', 'DEBE CONFIRMAR UBICACION', {duration});
        withError = true;
      }
      if (isNil(client.foto)) {
        this.toastr.danger('', 'DEBE TOMAR FOTO', {duration});
        withError = true;
      }
      resolve(withError);
    });
  }

  clear() {
    const dialog = this.dialog.open(AlertComponent,
      {
        context: {
          body: 'Se va a limpiar la información del cliente ¿quiere continuar?',
        }
      }).onClose.subscribe((answer) => {
        if (answer) {
          this._form.reset({
            dias: 0
          });
        }
        dialog.unsubscribe();
      });
  }

  changeState() {
    const dialog = this.dialog.open(AlertComponent,
      {
        context: {
          body: 'Se va a cambiar de estado al cliente ¿quiere continuar?',
        }
      }).onClose.subscribe((answer) => {
        if (answer) {
          this._active = !this._active;
          this._form.patchValue({
            activo: this._active
          });
        }
        dialog.unsubscribe();
      });
  }

  get page(): string {
    return this._page;
  }

  get active(): boolean {
    return this._active;
  }
}
