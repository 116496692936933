import { Component, OnInit } from '@angular/core';
import { OrderDetail } from 'src/app/lib/classes/order';
import { NbDialogRef } from '@nebular/theme';
import { round } from 'lodash';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  private _products: OrderDetail[];
  private _total = 0;

  constructor(private ref: NbDialogRef<boolean>) { }

  ngOnInit() {
    this.totalProduct();
  }

  totalProduct() {
    this._total = this._products.reduce((total, detail) => {
      return total += round(detail.subTotal, 2);
    }, 0);
  }

  close() {
    this.ref.close();
  }

  delete(product: OrderDetail) {
    this._products.splice(this._products.indexOf(product), 1);
    this.totalProduct();
  }

  get products(): OrderDetail[] {
    return this._products;
  }

  set products(value: OrderDetail[]) {
    this._products = value;
  }

  get total(): number {
    return this._total;
  }

}
