import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  private _title = '';
  private _typeText = 'text';
  private _filtros: any[] = [];
  private _check = null;
  private _searchBy = '';
  private _search: EventEmitter<any> = new EventEmitter();
  private _like = true;

  constructor() { }

  ngOnInit() {
  }

  fiterByEnter(event) {
    if (event.keyCode === 13) {
      this.filter();
    }
  }

  filter() {
    const filterParameters = [this._check, this._searchBy, this._like];
    this._search.emit(filterParameters);
  }

  get title(): string {
    return this._title;
  }

  @Input() set title(value: string) {
    this._title = value;
  }

  get typeText(): string {
    return this._typeText;
  }

  @Input() set typeText(value: string) {
    this._typeText = value;
  }

  get filtros(): any[] {
    return this._filtros;
  }

  @Input() set filtros(value: any[]) {
    this._filtros = value;
  }

  get check(): string {
    return this._check;
  }

  @Input() set check(value: string) {
    this._check = value;
  }

  get searchBy(): string {
    return this._searchBy;
  }

  set searchBy(value: string) {
    this._searchBy = value;
  }

  get like(): boolean {
    return this._like;
  }

  set like(value: boolean) {
    this._like = value;
  }

  @Output() get search(): EventEmitter<any> {
    return this._search;
  }



}
