<div class="d-flex justify-content-center align-items-center h-100">
  <nb-card size="large" class="m-0 w-95">
    <nb-card-header>
      <app-search title="Cliente" typeText="text" [filtros]="filtros" check="nombreCompleto" like="true"
        (search)="filterBy($event)"></app-search>
    </nb-card-header>
    <nb-card-body>
      <nb-list>
        <nb-list-item *ngFor="let client of data" (click)="edit(client.id)">
          <nb-user [name]="client.entidad?.nombreCompleto" [title]="client.codigo + ' - '+client.nombreNegocio">
          </nb-user>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-10">
          <app-paginator (pageChanged)='load($event)' [pages]="pages"></app-paginator>
        </div>
        <div class="col-2">
          <button type="button" (click)="addNew()" nbButton shape="round" size="small" status="primary">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</div>