import { Injectable } from '@angular/core';
import { CoreService } from '../lib/services/core.service';
import { AgrupadorMOV } from '../lib/classes/product';
import { PayForm } from '../lib/classes/order';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private _core: CoreService) { }
  resolve(): Promise<any> {
    this._core.api.add<any>('client', 'cliente');
    this._core.api.add<AgrupadorMOV>('agrupador-mov', 'agrupador-mov');
    this._core.api.add<PayForm>('pay-form', 'forma-pago');
    this._core.api.add<PayForm>('route', 'ruta');
    return Promise.resolve();
  }
}
