import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CoreService } from '../lib/services/core.service';
import { NbToastrService } from '@nebular/theme';
import { isNil } from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CheckListGuard implements CanActivate {
  constructor(private _core: CoreService, private router: Router, private toastr: NbToastrService, private storage: LocalStorageService) {
  }

  async canActivate() {
    if (await this.validCheckList()) {
      return true;
    }
  }


  async validCheckList() {
    try {
      this._core.spinnerOn();
      const resp: any = await this._core.api.get('route').find(null, `${this._core.user._id}/vendedor`).toPromise();
      const checkList = (!isNil(resp) ? resp.res: null);
      if (!isNil(checkList)) {
        if (!isNil(checkList.id)) {
          this.storage.store('route', checkList.id);
        }
        if (!isNil(checkList.bodegaId)) {
          this.storage.store('warehouse', checkList.bodegaId);
        }
        if (!isNil(checkList.estado)) {
          if (checkList.estado === 0) {
            this.router.navigate(['/start/check-list']);
          }
          if (checkList.estado === 1) {
            this.storage.store('haveRoute', true);
          }
        }
      }
      return true;
    } catch (error) {
      this.toastr.danger(error.error.error, 'Ruta');
      this.router.navigate(['/']);
      return false;
    } finally {
      this._core.spinnerOff();
    }
  }

}
