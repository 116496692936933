import { EntityService, Base } from '../rest/rest-api';
import { Filter } from './core';


export class List<T extends Base> {
  protected _rsc: EntityService<T>;
  protected _data: T;
  protected _filtros: Filter[];
  protected _pages = 0;
  protected _filterBy = '';

  constructor() { }

  get data() {
    return this._data;
  }

  get filtros(): any[] {
    return this._filtros;
  }

  get pages(): number {
    return this._pages;
  }
}
