import { NgModule } from '@angular/core';
import { ListComponent } from './list/list.component';
import { NewEditComponent } from './new-edit/new-edit.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClientComponent } from './components/client/client.component';
import { ProductComponent } from './components/product/product.component';
import { OrderComponent } from './components/order/order.component';
import { PayComponent } from './components/pay/pay.component';
import { SummaryComponent } from './components/summary/summary.component';
import { ComponentsModule } from 'src/app/lib/components/components.module';
import { ProductListComponent } from './components/product-list/product-list.component';
import { PaymentListComponent } from './components/payment-list/payment-list.component';
import { SummaryPedidoUserComponent } from './summary-pedido-user/summary-pedido-user.component';
import { VoidDocumentComponent } from './components/void-document/void-document.component';
import { GeneralDiscountComponent } from './components/general-discount/general-discount.component';

@NgModule({
  declarations: [
    ListComponent,
    NewEditComponent,
    ClientComponent,
    ProductComponent,
    OrderComponent,
    PayComponent,
    SummaryComponent,
    ProductListComponent,
    PaymentListComponent,
    SummaryPedidoUserComponent,
    VoidDocumentComponent,
    GeneralDiscountComponent
  ],
  imports: [
    SharedModule,
    ComponentsModule
  ],
  entryComponents: [ProductListComponent, PaymentListComponent, VoidDocumentComponent, GeneralDiscountComponent]
})
export class PedidoModule { }
