import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Order } from 'src/app/lib/classes/order';
import { Form } from 'src/app/lib/classes/form';
import { round } from 'lodash';
import { CoreService } from 'src/app/lib/services/core.service';
import { NbToastrService } from '@nebular/theme';
import { EntityService, Res } from 'src/app/lib/rest/rest-api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent extends Form<Order> implements OnInit {

  private _rscOrder: EntityService<Order>;
  private _rscPrint: EntityService<any>;
  private _amountPayment = 0;
  @Output() actualPage = new EventEmitter();
  constructor(private _core: CoreService, private toastr: NbToastrService, private router: Router) {
    super();
    this._rscOrder = this._core.api.get('order');
    this._rscPrint = this._core.api.get('print');
  }

  ngOnInit() {
    const amountProducts = this.form.value.detalle.reduce((total, detail) => total += round(detail.subTotal, 2), 0);
    this._amountPayment = this.form.value.detallePagos.reduce((total, detail) => total += round(detail.valor, 2), 0);
    this._form.patchValue({ subTotal: amountProducts });
  }

  async save() {
    // const amountProducts = this.form.value.detalle.reduce((total, detail) => total += round(detail.subTotal, 2), 0);
    // const amountPayment = this.form.value.detallePagos.reduce((total, detail) => total += round(detail.valor, 2), 0);

    if ((round(this._form.value.subTotal - this._form.value.descuento, 2)) !== (round(this._amountPayment, 2))) {
      this.toastr.warning('El monto por pagar no esta completo', 'Alerta');
      return;
    }
    try {
      this._core.spinnerOn();
      const idDocument: Res<Order> = await this._rscOrder.insert(this._form.value).toPromise() as Res<Order>;

      const url = encodeURI(`hhttps://api.gapa.work/v1/impresion/${idDocument.res.id}`);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `5d35365619b48b398435f843.pdf`);
      link.click();
      this.router.navigate(['/start/menu']);
    } catch (error) {
      this.toastr.danger(error.error.errores, 'Error');
    } finally {
      this._core.spinnerOff();
    }
  }

  goPay() {
    this.actualPage.emit('pay');
  }

  get amountPayment(): number {
    return this._amountPayment;
  }

}
