import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent as OrderList } from './pedido/list/list.component';
import { OrderService } from './order.service';
import { NewEditComponent as OrderNewEdit } from './pedido/new-edit/new-edit.component';
import { SummaryPedidoUserComponent } from './pedido/summary-pedido-user/summary-pedido-user.component';
import { AuthGuardService } from '../guards/auth-guard.service';
import { CheckListGuard } from '../guards/check-list.guard';
import { RouteGuard } from '../guards/route.guard';
import { CheckRouteGuard } from '../guards/check-route.guard';

const routes: Routes = [{
  path: '',
  component: OrderList,
  canActivate: [AuthGuardService],
  resolve: {
    service: OrderService
  }
}, {
  path: 'new',
  component: OrderNewEdit,
  canActivate: [AuthGuardService, CheckListGuard, RouteGuard, CheckRouteGuard],
  resolve: {
    service: OrderService
  }
}, {
  path: 'edit/:id',
  component: OrderNewEdit,
  canActivate: [AuthGuardService, CheckListGuard, CheckRouteGuard],
  resolve: {
    service: OrderService
  }
}, {
  path: 'summary',
  component: SummaryPedidoUserComponent,
  canActivate: [AuthGuardService, CheckListGuard, CheckRouteGuard],
  resolve: {
    service: OrderService
  }
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class RoutingModule { }
